import React, { FunctionComponent } from 'react';
import { Contact, Layout } from '../components';

const ContactPage: FunctionComponent = () => (
  <Layout>
    <Contact />
  </Layout>
);

export default ContactPage;
